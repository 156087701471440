<template>
  <div>
    <b-container fluid>
      <b-row
        v-if="banner_image"
        id="banner"
        :style="{ backgroundImage: `url(${getImagePath(banner_image, {width: screen_width})})` }"
        class=""
      >
        <div class="w-100 h-100 gradient-backdrop" :class="{'darker-gradient-backdrop': use_darker_gradient}">
          <b-row>
            <b-col cols="12" class="px-0 mx-0">
              <b-button
                variant="link"
                class="p-0 float-left"
                @click="backButton"
                ><img
                  class="align-top banner-button"
                  src="../../assets/back-icon.svg"
                  @click="backButton"
                  alt="placeholder"
                />
              </b-button>
              <b-button
                v-b-toggle.menu-sidebar
                variant="link"
                class="float-right p-0 pl-3"
              >
                <img
                  src="../../assets/hamburguer-menu-light.svg"
                  class="align-top nav-bar-button-icon banner-button"
                  alt="Logo"
                />
              </b-button>
              <PlaylistActions
                v-bind:playlist="asset"
              ></PlaylistActions>
            </b-col>

            <b-col cols="12" class="text-center text-light">
              <h1 class="banner-title" :class="use_thin_title ? 'font-100' : 'font-weight-light'">{{ banner_title }}</h1>
              <h4 class="font-weight-light banner-text">
                <span class="banner-text">{{
                  banner_text
                }}</span>
              </h4>
            </b-col>
          </b-row>
        </div>
      </b-row>

      <b-row v-else id="banner" class="image-backdrop" :class="random_colour">
        <div class="w-100 h-100 w-100 h-100 gradient-backdrop">
          <b-row>
              <b-col cols="12" class="px-0 mx-0">
                <b-button
                  variant="link"
                  class="p-0 float-left"
                  @click="$router.go(-1)"
                  ><img
                    class="align-top banner-button"
                    src="../../assets/back-icon.svg"
                    @click="$router.go(-1)"
                    alt="placeholder"
                  />
                </b-button>
                <b-button
                  v-b-toggle.menu-sidebar
                  variant="link"
                  class="float-right p-0 pl-3"
                >
                  <img
                    src="../../assets/hamburguer-menu-light.svg"
                    class="align-top nav-bar-button-icon banner-button"
                    alt="Logo"
                  />
                </b-button>
                <PlaylistActions
                  v-bind:playlist="asset"
                ></PlaylistActions>
              </b-col>

              <b-col cols="12" class="text-center text-light">
                <h1 class="banner-title" :class="use_thin_title ? 'font-100' : 'font-weight-light'">{{ banner_title }}</h1>
                <h4 class="font-weight-light banner-text">
                  <span class="banner-text">{{
                    banner_text
                  }}</span>
                </h4>
              </b-col>
            </b-row>
        </div>
        
      </b-row>

    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PlaylistActions from "../ancillary/playlist/actions.vue";

export default {
  name: "Banner",
  props: ["asset",],
  components: {
    PlaylistActions,
  },
  methods: {
    backButton() {
      if (this.getPreviousRoute) {
        this.$router.go(-1);
      }
      else {
        this.$router.push({name: 'Home'});
      }
      
    }
  },
  computed: {
    ...mapGetters(['getPreviousRoute']),
    ...mapGetters(["my_user_id", "isAuthenticated"]),
    ...mapGetters("Generic", { random_colour: "getColour" }),
    banner_image() {

      if(this.asset.banner_images.length > 0) {
        return this.asset.banner_images[0]
      }

      return null;
    },
    banner_title() {
      return this.asset.name
    },
    banner_text() {
      return this.asset.created_by
    },
    use_darker_gradient() {
      if (this.$route.name === 'ArtistUsername') {
        return true;
      }
      else {
        return false
      }
    },
    use_thin_title() {
      if (this.$route.name === 'ArtistUsername') {
        return true;
      }
      else {
        return false
      }
    }
  },
};
</script>

<style scoped>
#banner {
  height: 320px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.darker-gradient-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.banner-button {
  width: 40px;
  height: 40px;
}

.banner-title {
  font-weight: 300;
  font-size: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-100 {
  font-weight: 100;
}

.banner-text {
  font-weight: 300;
  font-size: 28px;
}

.container-buttons {
  max-width: 100% !important;
  padding-left: 0px;
  padding-right: 0px;
}

.follow-btn {
  border-width: 2px;
  width: 132px;
  height: 40px;
}

.btn-square {
  border-radius: 0;
}

/* Song */

.song-download-button-img {
  width: 40px;
}

.song-like-button-img {
  width: 43px;
}

.song-comment-button-img {
  width: 40px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (max-width: 768px) {
  .gradient-backdrop {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .banner-title {
    font-weight: 300;
    font-size: 48px;
  }

  .banner-text {
    font-weight: 300;
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  #banner {
    height: 240px;
  }

  .gradient-backdrop {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner-title {
    font-weight: 300;
    font-size: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-text {
    font-weight: 300;
    font-size: 20px;
  }

  .banner-button {
    width: 30px;
    height: 30px;
  }

  .song-download-button-img {
    width: 30px;
  }

  .song-like-button-img {
    width: 33px;
  }

  .song-comment-button-img {
    width: 30px;
  }

  .artist-follow-button {
    font-size: 15px;
  }
}

@media (max-width: 414px) {
  #banner {
    height: 200px;
  }

  .gradient-backdrop {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .banner-title {
    font-weight: 300;
    font-size: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-text {
    font-weight: 300;
    font-size: 16px;
  }

  .song-download-button-img {
    width: 25px;
  }

  .song-like-button-img {
    width: 28px;
  }

  .song-comment-button-img {
    width: 25px;
  }

  .banner-link {
    padding-left: 6px;
    padding-right: 6px;
  }

  .container-buttons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 375px) {
  .artist-follow-button {
    font-size: 15px;
    font-weight: 400;
    line-height: 10px;
    height: 26px;
    width: 86px;
    padding-left: 21px !important;
  }

  .artist-follow-button--unfollow {
    padding-left: 14px !important;
  }
}

@media (max-width: 320px) {
  .gradient-backdrop {
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .banner-button {
    width: 25px;
    height: 25px;
  }

  .container-buttons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>
