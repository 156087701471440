<template>
  <div class="" v-if="loaded" :class="{'header-margin': !isAuthenticated && showHeaderMargin()}">
    <b-container fluid class="px-0">
      <PlaylistBanner v-bind:asset="playlist"></PlaylistBanner>
    </b-container>

    <b-container class="py-2">
      <div class="d-flex justify-content-between text-white">
        <div class="d-flex" style="align-items:center">
          <div class="songs">
            <span v-if="search_results.length === 0"
              >{{ songs.length }}
              {{ songs.length > 1 ? "Songs" : "Song" }}</span
            >
            <span v-else
              >{{ search_results.length }}
              {{
                search_results.length > 1 ? "Results Found" : "Result Found"
              }}</span
            >
          </div>
        </div>
        <div class="d-flex">
          <toggler
            v-if="playlist.user_id === my_user_id"
            v-model="playlist.private"
            color="#00B884"
            class="mr-0 mr-md-4"
            ><span class="switch-button-label text-grey">
              Make Private</span
            ></toggler
          >
          <span class="song_toggle">
            <b-button
              v-if="playlist.liked"
              variant="link"
              @click="unlikePlaylist()"
              class=" pr-0"
            >
              <img
                src="../../../assets/heart-filled.svg"
                class="align-top text-white pr-0 song-heart-nav"
                height="30"
                alt="Menu"
              />
            </b-button>
            <b-button
              v-else
              variant="link"
              @click="likePlaylist()"
              class=" pr-0"
            >
              <img
                src="../../../assets/heart.svg"
                class="align-top text-white pr-0 song-heart-nav"
                height="30"
                alt="Menu"
              />
            </b-button>
          </span>
        </div>
      </div>
    </b-container>
    <div style="border-bottom: 1px solid #9B9B9B"></div>

    <b-container v-if="playlist">
      <div class="mb-3">
        <label class="sr-only" for="input-search">Search</label>
        <b-input-group
          class="c-playlist-input-search mb-2 mt-4 mr-sm-2 mb-sm-0"
        >
          <b-input-group-prepend
            class="px-2 search-input playlist-search c-search-icon"
            style="
              margin-right:0px;
              border-top-left-radius: 0.45rem;
              border-bottom-left-radius: 0.45rem;
            "
            :class="{ 'mobile-search-bar': isMobile() }"
          >
            <b-icon-search font-scale="1.2" class="mx-auto "></b-icon-search>
          </b-input-group-prepend>
          <b-form-input
            id="input-search"
            class="search-input playlist-search font-weight-light"
            :class="{ 'mobile-search-bar': isMobile() }"
            placeholder="Search Playlist"
            v-model="search_param"
            v-on:input="search_change()"
          ></b-form-input>
          <b-input-group-append
            v-if="search_param"
            class="px-2 search-input playlist-search c-clear-search-btn"
            style="
              border-top-right-radius: 0.45rem;
              border-bottom-right-radius: 0.45rem;
              margin-left:0px;
              
            "
          >
            <b-icon-x-circle-fill
              v-if="search_param"
              font-scale="1.2"
              class="mx-auto"
              style="color: #8E8E93;height:100%;"
              @click="clearSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </div>

      <div v-if="search_results.length === 0">
        <b-row v-for="(song, index) in songs" :key="index">
          <b-col cols="12" class="text-center text-light text-left mt-3 mb-3">
            <b-row class="px-0 playlist">
              <b-col cols="2" md="1" class="pr-0 pr-md-3">
                <player
                  v-if="playlist.user_id === my_user_id"
                  :image="song.song_image"
                  :file="song.song_url"
                  :id="song.id"
                  :title="song.song_name"
                  :username="song.artist_name"
                  :queue="songs"
                  :chipin_plus="song.chipin_plus"
                  :locked="song.locked"
                  player_type="playlist"
                  :artist_id="song.artist_id"
                  :indexed="index"
                ></player>
                <player
                  v-else
                  :chipin_plus="song.chipin_plus"
                  :locked="song.locked"
                  :image="song.song_image"
                  :file="song.song_url"
                  :id="song.id"
                  :title="song.song_name"
                  :username="song.artist_name"
                  player_type="playlist"
                  :artist_id="song.artist_id"
                  :queue="songs"
                  :indexed="index"
                ></player>
              </b-col>

              <b-col class="playlist-text">
                <img
                  v-if="song.chipin_plus && song.locked"
                  src="../../../assets/chipin+-locked-light.svg"
                  class="song-locked float-left mr-2 mr-md-3"
                  height="30"
                  alt="Logo"
                />

                <router-link
                  class="text-light song_title float-left mt-3"
                  :to="{
                    name: 'SongProfile',
                    params: { username: song.artist_name, id: song.song_id },
                  }"
                >
                  {{ song.song_name }}
                </router-link>
                <router-link
                  class="text-light song_author float-left ml-3 ml-md-4 mt-3"
                  :to="{
                    name: 'ArtistUsername',
                    params: { id: song.artist_name },
                  }"
                  >{{ song.artist_name }}
                </router-link>
              </b-col>

              <b-col cols="5" md="4" lg="3" class="pl-0 pl-md-3">
                <SongActions
                  v-bind:playlist="playlist"
                  v-bind:song="song"
                  v-bind:index="index"
                ></SongActions>

                <b-button
                  v-if="!song.liked"
                  @click="likeSong(song.song_id, index)"
                  variant="link"
                  class="float-right mt-3 p-0"
                >
                  <img
                    src="../../../assets/heart.svg"
                    class="align-top song-heart"
                    alt="Menu"
                  />
                </b-button>

                <b-button
                  v-if="song.liked"
                  @click="unlikeSong(song.song_id, index)"
                  variant="link"
                  class="float-right mt-3 p-0"
                >
                  <img
                    src="../../../assets/heart-filled.svg"
                    class="align-top song-heart"
                    alt="Menu"
                  />
                </b-button>

                <span class="song_play_info float-right px-1 mr-2 mr-md-3 mt-3">
                  {{ song.likes }}
                </span>

                <span
                  class="song_play_info song_play_info_timecode float-right px-1 mr-2 mr-md-3 mt-3"
                >
                  {{ song.song_timecode }}
                </span>
              </b-col>

              <chipinplus
                v-if="song.chipin_plus"
                v-bind:user_id="song.artist_id"
                v-bind:username="song.artist_name"
                v-bind:index="index"
                v-bind:stripe_type="song.made_on_stripe_account"
              ></chipinplus>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <div v-else>
        <!-- Search Results -->
        <b-row v-for="(song, index) in search_results" :key="index">
          <b-col cols="12" class="text-center text-light text-left mt-3 mb-3">
            <b-row class="px-0 playlist">
              <b-col cols="2" md="1" class="pr-0 pr-md-3">
                <player
                  v-if="playlist.user_id === my_user_id"
                  v-bind:image="song.song_image"
                  v-bind:file="song.song_url"
                  v-bind:id="song.song_id"
                  v-bind:title="song.song_name"
                  v-bind:username="song.artist_name"
                  v-bind:queue="songs"
                  player_type="playlist"
                ></player>
                <player
                  v-else
                  v-bind:chipin_plus="song.chipin_plus"
                  v-bind:locked="song.locked"
                  v-bind:image="song.song_image"
                  v-bind:file="song.song_url"
                  v-bind:id="song.user_id"
                  v-bind:title="song.song_name"
                  v-bind:username="song.artist_name"
                  v-bind:artist_id="song.artist_id"
                  player_type="playlist"
                  v-bind:indexed="index"
                ></player>
              </b-col>

              <b-col cols="3">
                <router-link
                  class="text-light song_title float-left mt-3"
                  :to="{
                    name: 'SongProfile',
                    params: { username: song.artist_name, id: song.song_id },
                  }"
                >
                  <img
                    v-if="song.chipin_plus && song.locked"
                    src="../../../assets/chipin+-locked-light.svg"
                    class="song-locked mr-3"
                    height="30"
                    alt="Logo"
                  />
                  {{ song.song_name }}
                </router-link>
                <router-link
                  class="text-light song_author float-left ml-3 ml-md-4 mt-3"
                  :to="{
                    name: 'SongProfile',
                    params: { username: song.artist_name, id: song.song_id },
                  }"
                  >{{ song.artist_name }}
                </router-link>
              </b-col>

              <b-col class="pl-0 pl-md-3">
                <SongActions
                  v-bind:playlist="playlist"
                  v-bind:song="song"
                  v-bind:index="index"
                ></SongActions>

                <b-button
                  v-if="!song.liked"
                  @click="likeSong(song.song_id, index)"
                  variant="link"
                  class="float-right mt-3 p-0"
                >
                  <img
                    src="../../../assets/heart.svg"
                    class="align-top song-heart"
                    alt="Menu"
                  />
                </b-button>

                <b-button
                  v-if="song.liked"
                  @click="unlikeSong(song.song_id, index)"
                  variant="link"
                  class="float-right mt-3 p-0"
                >
                  <img
                    src="../../../assets/heart-filled.svg"
                    class="align-top song-heart"
                    alt="Menu"
                  />
                </b-button>

                <span class="song_play_info float-right px-1 mr-2 mr-md-3 mt-3">
                  {{ song.likes }}
                </span>

                <span
                  class="song_play_info song_play_info_timecode float-right px-1 mr-2 mr-md-3 mt-3"
                >
                  {{ song.song_timecode }}
                </span>
              </b-col>

              <chipinplus
                v-if="song.chipin_plus"
                v-bind:user_id="song.user_id"
                v-bind:username="song.artist_name"
                v-bind:index="index"
                v-bind:stripe_type="song.made_on_stripe_account"
              ></chipinplus>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <chipinplustellmemore></chipinplustellmemore>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import player from "../../../components/player.vue";
import toggler from "../../../components/toggler.vue";
import PlaylistBanner from "../../../components/library/PlaylistBanner.vue";
import SongActions from "../../../components/ancillary/playlist/song/actions.vue";
import chipinplus from "../../../components/chipinplus-indexed.vue";
import chipinplustellmemore from "../../../components/chipinplus-tellmemore.vue";

export default {
  name: "Playlist",
  data() {
    return {
      loaded: false,
      search_param: null,
      search_results: [],
      playlist: null,
      songs: [],
      private_view: true,
      navbar: {
        open: true,
        collapse: false,
      },
    };
  },
  components: {
    player,
    toggler,
    PlaylistBanner,
    SongActions,
    chipinplus,
    chipinplustellmemore,
  },

  created() {},

  methods: {
    ...mapMutations("Player", ["addSong", "clearQueue"]),
    ...mapActions("Playlist", [
      "get_playlist",
      "get_private_playlist",
      "update_playlist",
    ]),
    ...mapActions("Song", ["like_song", "unlike_song"]),
    ...mapActions("Playlist", [
      "like_playlist",
      "unlike_playlist",
      "search_playlist",
    ]),
    ...mapMutations(['kochavaEvent']),

    showHeaderMargin() {
      if (this.$route.name === 'Playlist' || this.$route.name === 'ArtistV3' || this.$route.name === 'ChipIn') {
        return true;
      } else {
        return false;
      }
    },
    removeSong(index) {
      this.songs.splice(index, 1);
    },
    clearSearch() {
      this.search_param = null;
      this.search_results = [];
    },
    search_change() {
      if (this.search_param.length > 3) {
        this.search_playlist([this.playlist.id, this.search_param])
          .then((res) => {
            this.search_results = res.data.results;
          })
          .catch((error) => {
            error
          });
      }
      if (!this.search_param) {
        this.search_param = null;
        this.search_results = [];
      }
    },

    likePlaylist() {
      this.playlist.liked = true;
      this.like_playlist(this.playlist.id);
    },

    unlikePlaylist() {
      this.playlist.liked = false;
      this.unlike_playlist(this.playlist.id);
    },

    makePrivate() {
      let data = {};
      data["private"] = true;
      this.update_playlist([this.playlist.id, data]);
      this.playlist.private = true;
    },

    unPrivate() {
      let data = {};
      data["private"] = false;
      this.update_playlist([this.playlist.id, data]);
      this.playlist.private = false;
    },

    likeSong(id, index) {
      this.like_song(id);

      /*result = this.songs.filter((obj) => {
        return obj.id === song;
      });*/

      this.playlist.songs[index].liked = true;
      this.playlist.songs[index].likes += 1;
    },

    unlikeSong(id, index) {
      this.unlike_song(id);

      /*result = this.songs.filter((obj) => {
        return obj.id === song;
      });*/

      this.songs[index].liked = false;
      this.songs[index].likes -= 1;
    },
  },
  computed: {
    ...mapGetters("Generic", { random_colour: "getColour" }),
    ...mapGetters(["my_user_id", "isAuthenticated"]),
    ...mapGetters("Player", {
      loadedSong: "loadedSong",
      getLoadedSong: "getLoadedSong",
      getLoadedSongData: "getLoadedSongData",
      showLockedModal: "showLockedModal",
    }),
  },
  mounted() {
    this.kochavaEvent("ViewPlaylist", {})
    var id = this.$route.params.id;

    if (parseInt(id)) {
      this.get_playlist(id)
        .then((res) => {
          this.loaded = true;
          this.playlist = res.data;
          this.songs = this.playlist.songs;
        })
        .catch((error) => {
          error
        });
      this.private_view = false;
    } else {
      this.private_view = true;
      this.get_private_playlist(id)
        .then((res) => {
          this.loaded = true;
          this.playlist = res.data;
          this.songs = this.playlist.songs;
        })
        .catch((error) => {
          error
        });
    }
  },
};
</script>

<style scoped>
p {
  font-size: 1.2em;
}

.header-margin {
  margin-top: 120px;
}

.song-locked {
  position: relative;
  top: 10px;
  width: 25px;
}

.song-heart {
  position: relative;
  top: -3px;
}
.image-backdrop {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-backdrop > div {
  height: 330px;
}

/* Switch */
.switch-button-control .switch-button.enabled .button {
  background-color: #81cd5c !important;
}

.switch-button-control .switch-button .button {
  background-color: #000 !important;
}

.song_title,
.songs {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  width: auto;
}

.song_author {
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0px;
  text-align: left;
}

.song_play_info {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: right;
}

.song_info {
  min-width: 250px;
}

.song-heart {
  height: 30px;
  position: relative;
  top: -6px;
}
.song-heart-nav {
  height: 30px;
}

.default-background {
  background: url("../../../assets/images/playlist_banner.jpg");
}

.photo-border {
  border: 7px solid #000;
}

.border-success-2 {
  border: 2px solid #28a745 !important;
}

.border-danger-2 {
  border: 2px solid #dc3545 !important;
}

.stats-number {
  font-size: 2.2em;
  line-height: 1em;
  font-weight: lighter;
}

.text-small {
  font-size: 0.6em;
}

.text-smaller {
  font-size: 0.8em;
}

.pb-8,
.py-8 {
  padding-bottom: 8rem !important;
}

.pt-8,
.py-8 {
  padding-top: 8rem !important;
}

.playlist-search .mobile-search-bar {
  color: #9b9b9b !important;
  background: rgba(255, 255, 255, 0.1) !important;
}

.c-search-icon svg {
  height: 100%;
}

.c-search-icon {
  background-color: #191919;
  color: #9b9b9b;
}

.c-clear-search-btn {
  background-color: #191919;
  color: #9b9b9b;
}

.text-grey {
  color: #9b9b9b;
}

@media (max-width: 992px) {
  .song_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    max-width: 200px;
    display: inline;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .positioning {
    position: relative;
    top: -125px !important;
  }

  .song_title,
  .songs {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
  }

  .song_author {
    font-size: 16px;
    font-weight: 200;
    line-height: 21px;
  }

  .song_play_info {
    font-size: 16px;
    font-weight: 200;
    line-height: 21px;
  }
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .positioning {
    position: relative;
    top: -125px !important;
  }
  .song_title {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }

  .song_author {
    font-size: 18px;
    font-weight: 200;
    line-height: 21px;
  }

  .song_play_info {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .positioning {
    position: relative;
    top: -125px !important;
  }
  .song_title,
  .songs {
    font-size: 24px;
    font-weight: 400;
    line-height: 21px;
  }

  .song_author {
    font-size: 24px;
    font-weight: 200;
    line-height: 21px;
  }

  .song_play_info {
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
  }
}

/*  Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .positioning {
    position: relative;
    top: -125px !important;
  }
}

/* mobile */

/*  Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .positioning,
  .positioning-mobile {
    position: relative;
    top: -100px !important;
  }

  .positioning-chipin-logo {
    position: relative;
    top: -100px !important;
  }

  .stats-number {
    font-size: 1.7em;
    line-height: 1em;
    font-weight: lighter;
  }

  .text-smaller {
    font-size: 0.6em;
  }

  .profile-mobile {
    text-align: left !important;
  }

  .song_info {
    margin: auto;
  }

  .playlist-text {
    padding-right: 0 !important;
  }

  .song-locked {
    width: 20px;
    top: 13px;
  }

  .song_title {
    width: 110px !important;
  }

  .song_author {
    max-width: 100px;
    margin-left: 3px !important;
    position: relative;
    top: -12px;
  }
}

/*  Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/*  Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/*  Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* .search_form input, .input-group-append, .input-group-prepend, .input-group-text{
    background: rgba(255,255,255,0.1) !important;
    color: #9B9B9B !important;
    border: none !important;
    font-size: 18px !important;
    line-height: 22px !important;

} */

@media (max-width: 414px) {
  .song-heart {
    height: 23px;
    margin-top: 3px;
    height: 22px;
    position: relative;
    top: -3px;
  }

  .song_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 60px;
    display: inline;
  }

  .song_author {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 60px;
    display: inline;
  }

  .switch-button-control .switch-button-label {
    font-size: 16px;
    margin: 0px;
  }
  .song_title {
    width: 110px !important;
  }

  .song_author {
    width: 100px !important;
    margin-left: 3px !important;
    position: relative;
    top: -12px;
  }
  .song_author {
    display: none;
  }
}

@media (max-width: 375px) {
  .song-heart {
    height: 22px;
    position: relative;
    top: -4px;
  }

  .song_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 50px;
    display: inline;
  }

  .song_author {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 50px;
    display: inline;
  }

  .switch-button-control .switch-button-label {
    font-size: 14px;
  }

  .song_play_info_timecode {
    display: none;
  }
}

@media (max-width: 320px) {
  .song-heart {
    height: 20px;
  }
  .song_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 80px !important;
    display: inline;
  }

  .song_author {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 40px;
    display: inline;
  }

  .song_author {
    display: none;
  }

  .song_play_info_timecode {
    display: none;
  }

  .playlist-text {
    padding-left: 20px;
  }

  .song-dot-modal-btn .fa-ellipsis-h {
    width: 20px !important;
  }
}
</style>
