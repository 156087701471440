<template>
  <b-modal
    id="report-playlist-modal"
    size="lg"
    centered
    hide-footer
    title-sr-only
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    title="Login"
    modal-class="dot-dot-dot-modal"
    @hide="onClose"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="12">
            <img
              src="../../../assets/trackd-icon-colours.svg"
              class="mb-4 float-right mt-2"
              @click="close()"
              height="35"
              alt="Logo"
            />
            <h4 class="font-weight-lighter text-left h2 mt-5 mb-5 mb-md-0">
              Report Playlist
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container fluid class="pb-0">
      <b-row>
        <div class="col-11 pl-3">
          <div v-if="success">
            <p class="font-weight-light text-success">
              Playlist has been reported.
            </p>
          </div>
          <div v-if="error">
            <p class="font-weight-light text-danger">
              There was a problem. please try again.
            </p>
          </div>

          <b-form @submit.stop.prevent="Submit" class="pb-2 mr-3">
            <b-form-group label="Reason" label-class="text-left font-weight-light ">
              <b-form-select
                v-model="form.reason"
                :options="this.options"
                :state="validateState('reason')"
                class="select-input text-left mr-3"
                value-field="id"
                text-field="option"
              ></b-form-select>
            </b-form-group>

            <b-form-group class="mt-3">
              <b-button
                @click="Submit"
                variant="info"
                size="md"
                class="px-3 py-2 btn-rounded float-right text-uppercase"
                >Report</b-button
              >
              <b-button
                @click="$bvModal.hide('report-playlist-modal')"
                variant="secondary"
                size="md"
                class="px-3 py-2 mr-3 btn-rounded float-right text-uppercase"
                >Close</b-button
              >
            </b-form-group>
          </b-form>
        </div>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ReportArtist",
  props: ["playlist"],
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      success: false,
      error: false,
      showAPIError: false,
      APIErrorText: null,
      responseMsg: null,
      responseClass: null,
      form: {
        reason: null,
      },
      options: [{ id: null, name: "Please select" }],
    };
  },
  validations: {
    form: {
      reason: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("Playlist", ["report_playlist"]),
    onClose() {
      this.success = false;
      this.error = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    Submit() {
      this.error = false;
      this.success = false;

      this.showAPIError = false;
      this.responseMsg = null;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.report_playlist(this.playlist.id, this.form)
        .then((res) => {
          res;

          this.$v.form.$reset();
          this.success = true;
          this.responseMsg = res.data.status;
          this.responseClass = "text-success";

          setTimeout(() => {
            this.$bvModal.hide("report-playlist-modal");
          }, 2500);
        })
        .catch((error) => {

          if (error.data.errors) {
            this.responseMsg = error.data.errors[0];
            this.responseClass = "text-danger";
          } else if (error.data.non_field_errors) {
            this.responseMsg = error.data.non_field_errors[0];
            this.responseClass = "text-danger";
          } else {
            this.responseMsg = error.data.message;
            this.responseClass = "text-danger";
          }
        });
    },
  },
  mounted() {
    this.success = false;
    this.error = false;

    this.options = [
      { id: null, option: "Please select" },
      ...this.playlist.report_options
    ];
  },
};
</script>

<style>
.dot-dot-dot-modal .modal-content {
  width: 400px;
  margin: auto;
}

.forget-link {
  text-decoration: none;
}
.rect-box {
  border-radius: 0 !important;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover {
  text-decoration: none;
}

.btn-rounded {
  border-radius: 10px;
}

.h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
}

.confirm-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  color: #9b9b9b;
}

.text-input {
  background-color: #222222;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.select-input {
  background: #0e0e0f url("../../../assets/select-box-arrow.svg") no-repeat
    right 0.75rem center/20px 15px !important;
  color: #9b9b9b;
  border-radius: 10px;
  border: none !important;
  font-size: 18px;
  font-weight: 300;
  text-align: left !important;
  text-align-last: left !important;
}

.form-control:focus {
  background-color: #222222;
  color: #9b9b9b;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #9b9b9b;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9b9b9b;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9b9b9b;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #9b9b9b;
}

.b-dropdown-form:focus {
  outline: none !important;
}
</style>
